:root {
  --max-width: 70rem;
  --border-radius: 0.5rem;
}
body.light-theme {
  --hueRotate: 150deg;
  --saturate: 10;

  --layout-color: 0.2;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
  --average-color-rgb: 255, 255, 255;
  --text-color: #000000;
  --hover-color: 26, 134, 210;

  --card-rgb: 161, 200, 223;
  --card-border-rgb: 131, 134, 135;

  --footer-divider: 0, 0, 0;
}

body:not(.light-theme) {
  --hueRotate: 120deg;
  --saturate: 100;

  --layout-color: 0.3;
  --background-start-rgb: 0, 144, 172;
  --background-end-rgb: 0, 36, 72;
  --average-color-rgb: 0, 90, 122;
  --text-color: #ffffff;
  --hover-color: 34, 178, 159;

  --card-rgb: 0, 0, 0;
  --card-border-rgb: 200, 200, 200;

  --footer-divider: 255, 255, 255;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

/* Website's gradient is here */
body {
  background-image: linear-gradient(
    135deg,
    rgb(var(--background-start-rgb)),
    rgb(var(--background-end-rgb))
  );
  color: var(--text-color);
}

a {
  color: inherit;
  text-decoration: none;
}
